import React from 'react';

import { graphql, Link } from 'gatsby';
import _ from 'lodash';

import Layout from '../components/layout';

import { DiscoverBody } from '../components/blocks/DiscoverBody';

const ProductPage = ({ data: { allFeature: { edges } } }) => {
  const filtered = edges.filter(e => e.node.quid != undefined);
  const chunked = _.chunk(filtered, 2);
  return (
    <Layout>
      <DiscoverBody>
        <div className="row">
          <div className="col-xs-0 col-sm-12 col-md-12 col-lg-12">

            <h3>Discover how SwitchSmith can work for you</h3>

            {
              chunked.map((pair, idx) => {

                  const lhs = pair[0].node;
                  const rhs = pair[1] && pair[1].node;

                  return (
                    <div key={idx} className="row mb2 center-lg">
                      <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 ">
                        <FeatureBox {...lhs} />
                      </div>

                      <div className="col-xs-1 col-sm-6 col-md-6 col-lg-6">
                        { rhs && <FeatureBox {...rhs} />}
                      </div>

                    </div>
                  );
                })
            }

          </div>
        </div>
      </DiscoverBody>
    </Layout>
  )
}

const FeatureBox = ({headline: {icon: { publicURL }}, quid: {what: { topic, description }}, path}) =>
  <Link className="link near-black hover-mid-gray grow dib w-100 ba b--light-gray" to={path}>
    <div className="flex flex-column shadow-5 pa2 h4">
      <div className="flex items-center">
        <img className="ml2 w2 h2 mb0" src={publicURL}/>
        <h4 className="ml3 tl mb0">{topic}</h4>
      </div>
      <span className="pl2 pr2 pt3 pb0 f6 tl h3">{description}</span>
    </div>
  </Link>



export default ProductPage;

export const query = graphql`
  query {
    allFeature(sort: {fields: importance, order: DESC}) {
      edges {
        node {
          ...featureFields
        }
      }
    }
  }
`
;